import ACTIONS from '../../actions';
import Api from '../../api';
import generateGuid from '../../utils/guid';

let store = null;

class NotificationsService {
  constructor(InitStore) {
    store = InitStore || this.getStore('Notifications');

    /**
     * Константы возможных значений класса сообщения.
     * @typedef {Map} Levels
     */
    this.levels = new Map([
      ['SUCCESS', 'success'],
      ['WARNING', 'warning'],
      ['FAILURE', 'failure'],
    ]);

    /**
     * Дефолтные значения времени скрытия сообщения в мс, в зависимости от класса сообщения.
     * @type {Object}
     */
    this.defaultTimeouts = {
      success: 7000,
      warning: 14000,
      failure: 21000,
    };
  }

  get = () => store.getState();

  subscribe = callback => store.subscribe(callback);

  /**
   * Отправляет сообщение в Slack.
   * @param {Object} payload - Параметры сообщения.
   * @param {string} [payload.text] - Сообщение.
   * @param {string} [payload.channel] - Канал в Slack, куда отсылается сообщение.
   * @param {string} [payload.username] - Пользователь, от имени которого отсылается сообщение.
   * @param {string} [payload.iconEmoji] - Иконка эмодзи.
   * @returns {Promise}
   */
  sendToSlack = payload => Api.Slack.send(payload);

  /**
   * Добавляет таймер сообщения в список таймеров отложенного удаления сообщения.
   * @param {string} id - id сообщения.
   * @param {number} timeout - Время жизни сообщения в мс.
   * @param {{value of Levels}} level - Класс сообщения(value в свойстве this.levels).
   * @returns {void}
   */
  addPostponeRemoveTimer = (id, timeout, level) => {
    const newTimeout = timeout || this.defaultTimeouts[level];

    const timerId = setTimeout(() => this.remove(id), newTimeout);
    const newTimers = [...this.get().timers, { id, timerId }];

    store.dispatch({
      type: ACTIONS.NOTIFICATIONS.UPDATETIMERS,
      payload: newTimers,
    });
  };

  /**
   * Очищает таймер из списка таймеров отложенного удаления сообщения.
   * @param {string} id - id сообщения.
   * @returns {void}
   */
  deletePostponeRemoveTimer = (id) => {
    const newTimers = [];

    this.get().timers.forEach((timer) => {
      if (id !== timer.id) {
        newTimers.push(timer);
      } else {
        clearTimeout(timer.timerId);
      }
    });

    store.dispatch({
      type: ACTIONS.NOTIFICATIONS.UPDATETIMERS,
      payload: newTimers,
    });
  };

  /**
   * Добавляет новое сообщение.
   * @param {Object} notice - Параметры сообщения.
   * @param {(string|jsx)} notice.message - Сообщение.
   * @param {value of Levels} notice.level - Класс сообщения(value в свойстве this.levels).
   * @param {string} [notice.header] - Заголовок сообщения.
   * @param {number} [notice.timeout] - Время жизни сообщения в мс.
   * @returns {void}
   */
  send = (notice) => {
    const id = generateGuid();
    const newNotice = { id, ...notice };
    const newNotifications = [newNotice, ...this.get().notifications];

    this.addPostponeRemoveTimer(id, notice.timeout, notice.level);

    store.dispatch({
      type: ACTIONS.NOTIFICATIONS.UPDATENOTIFICATIONS,
      payload: newNotifications,
    });
  };

  /**
   * Удаляет сообщение из списка.
   * @param {string} id - id сообщения.
   * @returns {void}
   */
  remove = (id) => {
    this.deletePostponeRemoveTimer(id);

    const newNotifications = [];
    this.get().notifications.forEach((notice) => {
      if (id !== notice.id) newNotifications.push(notice);
    });

    store.dispatch({
      type: ACTIONS.NOTIFICATIONS.UPDATENOTIFICATIONS,
      payload: newNotifications,
    });
  };
}

export default NotificationsService;
