import ACTIONS from '../../actions';
import Api from '../../api';

let store = null;

class SearchUsersService {
  constructor(InitStore) {
    store = InitStore || this.getStore('SearchUsers');
  }

  setSearchUsers(label) {
    store.dispatch({
      type: ACTIONS.SEARCHUSERS.SETSEARCHUSERS,
      label,
    });
  }

  unsetSearchUsers() {
    store.dispatch({
      type: ACTIONS.SEARCHUSERS.UNSETSEARCHUSERS,
    });
  }

  changeSearchUsersValue(value) {
    store.dispatch({
      type: ACTIONS.SEARCHUSERS.CHANGESEARCHUSERSVALUE,
      value,
    });
  }

  searchUsers = value => Api.Users.searchUsers(value).then((res) => {
    store.dispatch({
      type: ACTIONS.SEARCHUSERS.UPDATEUSERSLIST,
      res,
    });
  });

  get() {
    return store.getState();
  }

  subscribe(callback) {
    return store.subscribe(callback);
  }

}

export default SearchUsersService;
