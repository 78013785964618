import Api from '../../api';
import ACTIONS from '../../actions';

let store = null;

export default class RailwayWarningService {
  constructor() {
    store = this.getStore('RailwayWarning');
  }

  get = () => store.getState();

  getListTemplateWarning = async () => {
    try {
      const data = await Api.TrainCancel.getListTemplateWarning();

      store.dispatch({
        type: ACTIONS.RAILWAY_WARNING.GET_LIST_TEMPLATE_WARNING,
        payload: data,
      });
    } catch (error) {
      store.dispatch({
        type: ACTIONS.RAILWAY_WARNING.SET_SERVER_ERROR,
      });
    }
  }

  addFieldTemplateWarning = async (params) => {
    try {
      const data = await Api.TrainCancel.addFieldTemplateWarning(params);

      store.dispatch({
        type: ACTIONS.RAILWAY_WARNING.GET_LIST_TEMPLATE_WARNING,
        payload: data,
      });
    } catch (error) {
      store.dispatch({
        type: ACTIONS.RAILWAY_WARNING.SET_SERVER_ERROR,
      });
    }
  }

  changeFieldTemplateWarning = async (params) => {
    try {
      await Api.TrainCancel.changeFieldTemplateWarning(params);

      store.dispatch({
        type: ACTIONS.RAILWAY_WARNING.CHANGE_FIELD_TEMPLATE_WARNING,
        payload: params,
      });
    } catch (error) {
      store.dispatch({
        type: ACTIONS.RAILWAY_WARNING.SET_SERVER_ERROR,
      });
    }
  }

  deleteFieldTemplateWarning = async (widgetId) => {
    try {
      await Api.TrainCancel.deleteFieldTemplateWarning(widgetId);

      store.dispatch({
        type: ACTIONS.RAILWAY_WARNING.DELETE_FIELD_TEMPLATE_WARNING,
        payload: widgetId,
      });
    } catch (error) {
      store.dispatch({
        type: ACTIONS.RAILWAY_WARNING.SET_SERVER_ERROR,
      });
    }
  }

  setAdditionTemplateWarning = value => store.dispatch({ type: ACTIONS.RAILWAY_WARNING.SET_ADDITION_FIELD_WARNING, payload: value });

  setIsEditing = value => store.dispatch({ type: ACTIONS.RAILWAY_WARNING.SET_IS_EDDITING, payload: value });

  setTextTemplateWarning = text => store.dispatch({ type: ACTIONS.RAILWAY_WARNING.SET_TEXT_TEPMLATE_WARNING, payload: text });

  subscribe = callback => store.subscribe(callback);
}
