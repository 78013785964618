import moment from 'moment';

import lodashReplaces from '../../../utils/lodashReplaces';

const NO_EMPTY = 'Поле не должно быть пустым';

const require = value => value !== '';

const staticSimpleValidStorage = {
  NameRu: {
    fn: require,
    msg: NO_EMPTY,
  },
  CountryNameRu: {
    fn: require,
    msg: NO_EMPTY,
  },
  NameEn: {
    fn: require,
    msg: NO_EMPTY,
  },
  CountryNameEn: {
    fn: require,
    msg: NO_EMPTY,
  },
  Radius: {
    fn: require,
    msg: NO_EMPTY,
  },
  Latitude: {
    fn: require,
    msg: NO_EMPTY,
  },
  Longitude: {
    fn: require,
    msg: NO_EMPTY,
  },
};

class Validator {
  isValid(path, value) {
    const validationPartObj = lodashReplaces.getValueInObjByPath(staticSimpleValidStorage, path);

    if (!validationPartObj) {
      return '';
    }

    const { fn, msg } = validationPartObj;

    return fn(value) ? '' : msg;
  }

  getValidationFields = (obj, validStorage) => Object.keys(obj).reduce((acc, key) => {
    const field = obj[key].value;
    const partValidObj = validStorage[key];

    if (!partValidObj) {
      return acc;
    }

    const { fn, msg } = partValidObj;
    const value = fn(field) ? '' : msg;

    return { ...acc, [key]: value };
  }, {});

  isValidFields = fields => Object.keys(fields)
    .every((key) => {
      const field = fields[key];

      return field instanceof Object && !moment.isMoment(field) ? this.isValidFields(field) : !field;
    });

  getValidation = (fields) => {
    const validation = this.getValidationFields(fields, staticSimpleValidStorage);

    return { validation, isValid: this.isValidFields(validation) };
  };
}

export default Validator;
