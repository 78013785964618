import Api from '../../api';
import ACTIONS from '../../actions';

let store = null;

class TrainCancelService {
  constructor() {
    store = this.getStore('TrainCancel');
  }

  get = () => store.getState();

  updateLoading = loading => store.dispatch({
    type: ACTIONS.TRAIN_CANCEL.UPDATE_LOADING,
    payload: loading,
  });

  cancelTicket = async (provider, orderId, ticketId) => {
    try {
      this.updateLoading(true);
      const data = await Api.TrainCancel.cancelTicket(provider, orderId, ticketId);

      store.dispatch({
        type: ACTIONS.TRAIN_CANCEL.CANCEL_TRAIN_ITEM,
        payload: data,
      });
    } catch (err) {
      store.dispatch({
        type: ACTIONS.TRAIN_CANCEL.REQUEST_ERROR,
      });
    }

    this.updateLoading(false);
  };

  resetStore = () => store.dispatch({
    type: ACTIONS.TRAIN_CANCEL.RESET,
  });

  subscribe = callback => store.subscribe(callback);
}

export default TrainCancelService;
