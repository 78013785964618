let store = null;

class OrderService {
  constructor() {
    store = this.getStore('Order');
  }

  subscribe(callback) {
    return store.subscribe(callback);
  }

  get() {
    return store.getState();
  }
}

export default OrderService;
