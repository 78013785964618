import ACTIONS from '../../actions';
import Api from '../../api';

let store = null;

class ReviseActTestService {
  TESTSTATUS = {
    0: {
      MESSAGE: 'Ошибка тестирования',
      CLASS: 'status-error',
    },
    1: {
      MESSAGE: 'В очереди',
      CLASS: 'status-queue',
    },
    2: {
      MESSAGE: 'В процессе',
      CLASS: 'status-process',
    },
    3: {
      MESSAGE: 'Выполнен',
      CLASS: 'status-completed',
    },
  };

  constructor() {
    store = this.getStore('reviseActTest');
  }

  get = () => store.getState();

  addNewTest = () => Api.ReviseActTest.addNewTest()
    .then(() => this.getTestList());

  getTestList = () => Api.ReviseActTest.getTestList()
    .then(res => store.dispatch({
      type: ACTIONS.REVISEACTTEST.LOADLISTACT,
      listAct: res,
    }));

  testResult = id => Api.ReviseActTest.testResult(id);

  subscribe = callback => store.subscribe(callback);
}

export default ReviseActTestService;
