import addAppService from './app';
import addUserService from './user';
import addWorkspaceService from './workplace';
import addFilterService from './filter';
import addAccountService from './account';
import addCompaniesService from './companies';
import addEmployeesService from './employees';
import addEmployeeService from './employee';
import addCompanyService from './company';
import addTripService from './trip';
import addGuidService from './guid';
import addOrderService from './order';
import addAirService from './air';
import addAirlineCancelService from './airlineCancel';
import addAirlineWarningService from './airlineWarning';
import addBusinessService from './business';
import addTrainsCancel from './trainCancel';
import addSearchUsersService from './seacrhUsers';
import addCartsAndNotesService from './cartsAndNotes';
import addReviseActTestService from './reviseacttest';
import addTravelPolicyService from './travelPolicy';
import addRightsService from './rights';
import addUnloading1cService from './unloading1c';
import addSendingDiadocService from './sendingDiadoc';
import addNotificationsService from './notifications';
import addFeatureFlagsService from './featureFlags';
import addAmoCRMService from './amocrm';
import addApproval from './approval';
import addEnumsService from './enums';
import addTripTagsService from './tripTags';
import addCallsService from './calls';
import addHotelsService from './hotels';
import addStaticsService from './statics';
import addRegionsService from './regions';
import addRailwayWarningService from './railwayWarning';
import addCalculatorService from './calculator';
import addAeroExpressService from './aeroexpress';
import addHotelWarningService from './hotelWarning';
import addTaxiWarningService from './taxiWarning';
import addTransferWarningService from './transferWarning';
import addVipHallWarningService from './vipHallWarning';
import addDocumentsConstructor from './documentsConstructor';

export default function (core) {
  addAppService(core);
  addUserService(core);
  addWorkspaceService(core);
  addFilterService(core);
  addAccountService(core);
  addCompaniesService(core);
  addEmployeesService(core);
  addEmployeeService(core);
  addCompanyService(core);
  addTripService(core);
  addGuidService(core);
  addOrderService(core);
  addAirService(core);
  addAeroExpressService(core);
  addAirlineCancelService(core);
  addAirlineWarningService(core);
  addBusinessService(core);
  addTrainsCancel(core);
  addSearchUsersService(core);
  addCartsAndNotesService(core);
  addReviseActTestService(core);
  addTravelPolicyService(core);
  addRightsService(core);
  addUnloading1cService(core);
  addSendingDiadocService(core);
  addNotificationsService(core);
  addFeatureFlagsService(core);
  addAmoCRMService(core);
  addApproval(core);
  addEnumsService(core);
  addTripTagsService(core);
  addCallsService(core);
  addHotelsService(core);
  addStaticsService(core);
  addRegionsService(core);
  addRailwayWarningService(core);
  addCalculatorService(core);
  addHotelWarningService(core);
  addTaxiWarningService(core);
  addTransferWarningService(core);
  addVipHallWarningService(core);
  addDocumentsConstructor(core);
}
