import Api from '../../api';
import ACTIONS from '../../actions';

let store = null;

export default class WarningService {

  constructor() {
    store = this.getStore('HotelWarning');
  }

  get = () => store.getState();

  getListTemplateWarning = async () => {
    const data = await Api.Warning.getListTemplateWarning('hotel');

    store.dispatch({
      type: ACTIONS.WARNING.GET_LIST_TEMPLATE_WARNING,
      payload: data,
    });
  }

  addFieldTemplateWarning = async (params) => {
    try {
      const data = await Api.Warning.addFieldTemplateWarning(params);

      store.dispatch({
        type: ACTIONS.WARNING.GET_LIST_TEMPLATE_WARNING,
        payload: data,
      });
    } catch (error) {
      store.dispatch({
        type: ACTIONS.WARNING.SET_SERVER_ERROR,
      });
    }
  }

  deleteFieldTemplateWarning = async (widgetId) => {
    try {
      await Api.Warning.deleteFieldTemplateWarning(widgetId);

      store.dispatch({
        type: ACTIONS.WARNING.DELETE_FIELD_TEMPLATE_WARNING,
        payload: widgetId,
      });
    } catch (error) {
      store.dispatch({
        type: ACTIONS.WARNING.SET_SERVER_ERROR,
      });
    }
  }

  changeFieldTemplateWarning = async (params) => {
    try {
      await Api.Warning.changeFieldTemplateWarning(params);

      store.dispatch({
        type: ACTIONS.WARNING.CHANGE_FIELD_TEMPLATE_WARNING,
        payload: params,
      });
    } catch (error) {
      store.dispatch({
        type: ACTIONS.WARNING.SET_SERVER_ERROR,
      });
    }
  }

  deleteFieldTemplateWarning = async (widgetId) => {
    const data = await Api.Warning.deleteFieldTemplateWarning(widgetId);

    store.dispatch({
      type: ACTIONS.WARNING.GET_LIST_TEMPLATE_WARNING,
      payload: data,
    });
  }

  setIsEditing = value => store.dispatch({ type: ACTIONS.WARNING.SET_IS_EDDITING, payload: value });

  setTextTemplateWarning = text => store.dispatch({ type: ACTIONS.WARNING.SET_TEXT_TEPMLATE_WARNING, payload: text });

  setAdditionTemplateWarning = value => store.dispatch({ type: ACTIONS.WARNING.SET_ADDITION_FIELD_WARNING, payload: value });

  subscribe = callback => store.subscribe(callback);
}
